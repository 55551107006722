.vh {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.vh.focusable:active,
.vh.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

.noscript-warning {
    margin: 0;
    text-align: center;
    padding: 20px;
    background-color: #666;
    color: #fff;
}

.old-browser {
    padding: 20px;
    background-color: #fff;
    color: #000;
    font-size: 16px;
    line-height: 22px;
    display: none;

    p {
        margin: 0 0 30px;

        &:last-child {
            margin: 0;
        }
    }

    &.is_visible {
        display: block;
    }
}
