.slider {
    background: linear-gradient(
                    rgba(0, 0, 0, 0.25),
                    rgba(0, 0, 0, 0.65)
    ), url('/assets/quotes.jpg') no-repeat center center fixed;
    background-size: cover;
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    position: relative;
    padding: 80px 0 60px;

    @media screen and (max-width: 1024px) {
        background-attachment: scroll;
    }

    &__slide {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        transform: scale(0);
        transition: .6s ease opacity, .6s ease transform;
    }

    h3,
    h4 {
        color: #fff;
        position: relative;
        z-index: 1;
    }

    h3 {
        padding: 0 20px;
        max-width: 1024px;
        width: 100%;
        position: relative;
    }

    h4 {
        margin-bottom: 0;
    }

    &__quote {
        font-size: 21px;
        position: relative;
        left: -10px;
        top: -15px;

        &_right {
            left: auto;
            right: -10px;
            top: -15px;
        }
    }

    &__slide.active {
        opacity: 1;
        transform: scale(1);
        transition-duration: .4s;
    }

    &__image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
