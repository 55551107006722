@import './_variables.scss';

.clients {
    background: $dark-blue;
    color: #fff;
    box-shadow: rgba(0, 0, 0, 0.26) 0 2px 5px;
    position: relative;

    &__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        max-width: 768px;
        margin: 30px auto 0;
        padding: 0 20px;
    }

    &__container {
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: 650px) {
            width: 33%;
            margin-bottom: 25px;

            &_4,
            &_5,
            &_6 {
                margin-bottom: 0;
            }
        }
    }

    &__client {
        display: inline-block;
        max-width: 90%;
    }

    p {
        padding: 0 20px;
    }
}
