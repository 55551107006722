@import './variables';

.contact {
    background: #fff;
    box-sizing: content-box;

    a {
        color: $dark-blue;
    }

    p {
        padding: 0 20px;
    }

    &__outer {
        position: relative;
        height: 352px;
    }

    &__submitted,
    &__container {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
    }

    &__submitted {
        background: rgb(17, 20, 50);
        justify-content: center;
        align-items: center;
        color: #fff;

        p {
            margin-bottom: 0;
        }
    }

    &__form {
        max-width: 768px;
        margin: 25px auto 0;
        padding: 0 20px;

        label,
        input,
        textarea,
        button {
            width: 100%;
        }

        input,
        textarea {
            border: 1px solid $blue;
            padding: 10px 7.5px;
            max-width: 100%;
            box-shadow: 0 0 5px 0 rgba(8, 128, 165, 0);
            transition: .4s ease box-shadow;

            &:focus {
                outline-style: outset;
                box-shadow: 0 0 5px 0 rgb(8, 128, 165);
            }
        }

        textarea {
            height: 150px;
            resize: none;
        }

        button {
            padding: 10px 7.5px;
            background: $dark-blue;
            border: none;
            color: #fff;
        }

        p {
            margin-bottom: 10px;
            padding: 0;
        }

        &-top {
            display: flex;
            justify-content: space-between;

            @media (max-width: 600px) {
                flex-direction: column;
            }

            label {
                width: calc(50% - 5px);

                @media (max-width: 600px) {
                    width: 100%;

                    &[for=name] {
                        margin-bottom: 10px;
                    }
                }
            }

            input {
                width: 100%;
            }
        }
    }
}
