@import './_variables.scss';

.about-me {
    background-color: #fff;

    p {
        max-width: 1024px;
        width: 100%;
        margin: 0 auto 25px;
        padding: 0 20px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    a {
        color: $dark-blue;
    }
}
