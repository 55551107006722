body {
    padding: 0;
    margin: 0;
}

body *,
body *::before,
body *::after {
    font-size: 20px;
    font-weight: normal;
    border-collapse: collapse;
    box-sizing: border-box;
}

input,
textarea,
select,
button {
    appearance: none;
}

button,
select,
label {
    cursor: pointer;
}

a,
button,
input,
textarea,
select,
summary {
    &:focus {
        outline: 0;
    }
}

html {
    text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}

a,
area,
button,
[role='button'],
input,
label,
select,
summary,
textarea {
    touch-action: manipulation;
}

nav ul,
nav ol {
    list-style: none;
    padding: 0;
    margin: 0;
}
