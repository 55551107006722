@import 'styles/sorta-reset';
@import 'styles/helpers';

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &.tabbing {
        *:focus {
            position: relative;
            &:before {
                position: absolute;
                width: calc(100% + 5px);
                height: calc(100% + 5px);
                box-shadow: 0 0 5px 0 rgb(8, 128, 165);
                content: '';
                left: -2.5px;
                top: -2.5px;
                outline-width: 3px;
                outline-offset: 0px;
                outline-color: rgb(9, 128, 165);
            }
        }
        button:focus {
            &:before {
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
            }
        }
        .landing {
            button:focus {
                position: absolute;
                box-shadow: 0 0 5px 0 rgb(255, 255, 255);
            }
        }
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

//@font-face {
//    font-family: 'RobotoMedium';
//    font-display: auto;
//    src: url('/assets/fonts/Roboto-Medium.ttf');
//}
//
//$RobotoMedium: RobotoMedium, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
//"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
//sans-serif;
//
//@font-face {
//    font-family: 'MontserratMedium';
//    font-display: auto;
//    src: url('/assets/fonts/Montserrat-Medium.ttf');
//}
//
//$MontserratMedium: MontserratMedium, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
//"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
//sans-serif;

@font-face {
    font-family: 'Lato';
    src: url('/assets/fonts/Lato-Regular.ttf');
    font-display: fallback;
}

$LatoRegular: Lato, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
sans-serif;

@font-face {
    font-family: 'Lato-Light';
    src: url('/assets/fonts/Lato-Light.ttf');
    font-display: fallback;
}

$LatoLight: Lato-Light, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
sans-serif;

h2, h3, h4, h5, h6, p, input, textarea, body {
    margin-top: 0;
    font-family: $LatoLight;
}

a, button {
    font-family: $LatoRegular;
}

h3 {
    font-size: 36px;
    margin-bottom: 20px;
    padding: 0 20px;
}

h1 {
    font-family: $LatoRegular;
    font-size: 40px;
    margin-bottom: 15px;
}

main {
    min-height: 100vh;
}

.about-me,
.contact {
    a {
        font-family: $LatoLight;
    }
}
