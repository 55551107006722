@import './_variables.scss';

header {
    display: flex;
    justify-content: space-between;
    padding: 20px 20px;
    position: fixed;
    width: 100%;
    box-sizing: border-box;
    z-index: 2;
    color: #fff;

    .logo {
        z-index: 2;
    }

    nav,
    ul {
        display: flex;
    }

    li {
        list-style-type: none;
        padding-left: 15px;
        display: flex;
        align-items: center;
    }

    a {
        color: #fff;
        font-size: 18px;
        text-decoration: none;
        position: relative;

        &:not(.logo) {
            padding-left: 20px;
        }

        &:after {
            content: '';
            width: 0;
            height: 1px;
            background: #fff;
            display: block;
            transition: .4s ease-in width;
        }

        &:hover {
            &:after {
                width: 100%;
            }
        }
    }

    svg {
        max-height: 50px;
    }

    path {
        transition: 2.5s ease stroke-dashoffset;
        stroke-dasharray: 2000px;
        stroke-dashoffset: 2000px;
    }

    g {
        stroke: #fff;
    }

    &.scrolled {
        a {
            &:after {
                background: $blue;
            }
        }

        path {
             stroke-dashoffset: 1px;
        }

        g {
            stroke: #111432;
        }
    }

    @media (min-width: 1025px) {
        .mobile {
            display: none;
        }
        nav {
            ul {
                display: flex !important;
                background: transparent !important;
            }
            li {
                opacity: 1 !important;
                transform: none !important;
            }
        }
    }

    @media (max-width: 1024px) {
        .mobile {
            position: fixed;
            right: 10px;
            top: 25px;
            z-index: 10;

            &__line {
                width: 30px;
                height: 3px;
                background: $dark-blue;
                position: relative;
                display: block;

                &.top {
                    top: -6px;
                }

                &.bot {
                    top: 6px;
                }
            }
        }
        nav {
            ul {
                position: absolute;
                width: 100%;
                height: 100vh;
                left: 0;
                top: 0;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }

            li {
                padding: 0;
            }

            a {
                color: #fff !important;
                font-size: 48px;
                padding: 10px;

                &:after {
                    background-color: #fff !important;
                }
            }
        }
    }
}
