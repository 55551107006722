.landing {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;

    video {
        position: fixed;
        z-index: -2;
        min-width: 100%;
        min-height: 100%;
    }

    &:before {
        content: '';
        width: 101%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-color: rgba(16, 19, 50, 0.88);
        z-index: 0;
    }

    &__text {
        position: relative;
        z-index: 1;
    }

    h1,
    h2,
    p {
        color: #fff;
        max-width: 450px;
    }

    h2 {
        line-height: 30px;
        font-weight: 400;
        padding: 0 20px;
    }

    button {
        position: absolute;
        left: 20px;
        bottom: 40px;
        background: transparent;
        border: none;
        color: #fff;
        font-size: 30px;
    }
}
