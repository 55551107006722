@import './_variables.scss';

footer {
    background: #fff;
    padding: 25px 20px 35px;
    display: flex;

    a {
        color: $blue;
        margin-right: 25px;
        transform: scale(1);
        transition: transform .4s ease;
        display: inline-block;

        &:hover {
            transform: scale(1.15);
        }
    }
}
